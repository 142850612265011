import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header'
import Footer from '../components/Footer';
import { MailchimpPopup } from '../components/MailchimpForm';
import SharePopup from '../components/SharePopup';
import SocialButtons from '../components/SocialButtons';
import { onAuthStateChanged } from 'firebase/auth';
import AdminMenu from '../components/LoggedInMenu';
import SponsorPopup from '../components/SponsorPopup';

import {PageContainer, ExitPopup, HeroSection, HeroContent, VideoContainer, VideoStatSection, Title, StatContainer, Stats, RaisedStat, QuantityStat, TreesStat, GoalText, CTAStrip, CTAContainer, CTAText, CTAIcon, AdvertStrip, AdvertCard, MapStrip} from '../components/StyledItems'

import { db, storage, auth } from '../util/firebaseApp';
import { doc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore'

const TestPage = (props) => {
    const [sharedState, setSharedState] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [bank, setBank] = useState({});
    const [bankId, setBankId] = useState("fundraise-recycle");
    const [searchParams] = useSearchParams();
    const [bankImg, setImage] = useState("");
    const [sponsors, setSponsors] = useState({});
    const [popupActive, setPopupState] = useState(false);
    const [sharePopupActive, setSharePopup] = useState(false);
    const navigate = useNavigate();
    const [shareCount, setShareCount] = useState(0);
    const [viewBankLoggedInState, setLoggedInState] = useState(false);
    const [sponsorPopupState, setSponsorPopupState] = useState(false);
    const [defaultSponsors, setDefaultSponsors] = useState([]);

    React.useEffect(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setLoggedInState(true);
          } else {
            setLoggedInState(false);
          }
        });
      }, []);

    const fetchBankData = async () => {
        const docRef = doc(db, "banks", bankId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setBank(docSnap.data());
            setShareCount(docSnap.data().shareCount ? docSnap.data().shareCount : 0);
            return docSnap.data();
        }
    }

    const fetchBankPhoto = () => {
        if (bank.image) {
            if (bank.image.slice(-1) === "4") {
                setIsVideo(true);
            }
            setImage("https://api.fundraiserecycle.com/media/" + bank.image)
        } else {
            setIsVideo(true);
            setImage("https://api.fundraiserecycle.com/media/bank-images/Jake%20Tutorial%20Reduced.mp4#t=0.1")
        }
    }

    const setSponsorImage = async (fileToGet, idToSet) => {
        if (fileToGet && idToSet) {
            document.getElementById(idToSet).src = ("https://api.fundraiserecycle.com/media/" + fileToGet);
        }
    }

    const fetchSponsors = async () => {
        var sponsorsTemp = [];

        fetchSponsorData(sponsorsTemp)
            .then(() => {
                if (sponsorsTemp.length > 0) {
                    setSponsors(sponsorsTemp);
                } else {
                    setSponsors(defaultSponsors);
                }
            })
    }

    const setupDefaultSponsors = async () => {
        let temp = [];
        const querySnapshot = await getDocs(collection(db, "default-sponsors"));
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });
        setDefaultSponsors(temp);
    }

    const fetchSponsorData = async (sponsorArray) => {
        let sponsor1 = {};
        let sponsor2 = {};
        let sponsor3 = {};
        let sponsor4 = {};
        let sponsor5 = {};
        let sponsor6 = {};
        let sponsor7 = {};
        let sponsor8 = {};

        if (bank.sponsor1name) {
            sponsor1["title"] = bank.sponsor1name;
            sponsor1["link"] = bank.sponsor1link;
            sponsor1["image"] = bank.sponsor1image; 
            sponsor1["text"] = bank.sponsor1text;
            sponsor1["id"] = "sponsor-1";
            setSponsorImage(bank.sponsor1image, "sponsor-1");

            sponsorArray.push(sponsor1);
        }

        if (bank.sponsor2name) {
            sponsor2["title"] = bank.sponsor2name;
            sponsor2["link"] = bank.sponsor2link;
            sponsor2["image"] = bank.sponsor2image; 
            sponsor2["text"] = bank.sponsor2text;
            sponsor2["id"] = "sponsor-2";
            setSponsorImage(bank.sponsor2image, "sponsor-2");

            sponsorArray.push(sponsor2);
        }

        if (bank.sponsor3name) {
            sponsor3["title"] = bank.sponsor3name;
            sponsor3["link"] = bank.sponsor3link;
            sponsor3["image"] = bank.sponsor3image; 
            sponsor3["text"] = bank.sponsor3text;
            sponsor3["id"] = "sponsor-3";
            setSponsorImage(bank.sponsor3image, "sponsor-3");
            
            sponsorArray.push(sponsor3);
        }

        if (bank.sponsor4name) {
            sponsor4["title"] = bank.sponsor4name;
            sponsor4["link"] = bank.sponsor4link;
            sponsor4["image"] = bank.sponsor4image; 
            sponsor4["text"] = bank.sponsor4text;
            sponsor4["id"] = "sponsor-4";
            setSponsorImage(bank.sponsor4image, "sponsor-4");

            sponsorArray.push(sponsor4);
        }

        if (bank.sponsor5name) {
            sponsor5["title"] = bank.sponsor5name;
            sponsor5["link"] = bank.sponsor5link;
            sponsor5["image"] = bank.sponsor5image; 
            sponsor5["text"] = bank.sponsor5text;
            sponsor5["id"] = "sponsor-5";
            setSponsorImage(bank.sponsor5image, "sponsor-5");

            sponsorArray.push(sponsor5);
        }

        if (bank.sponsor6name) {
            sponsor6["title"] = bank.sponsor6name;
            sponsor6["link"] = bank.sponsor6link;
            sponsor6["image"] = bank.sponsor6image; 
            sponsor6["text"] = bank.sponsor6text;
            sponsor6["id"] = "sponsor-6";
            setSponsorImage(bank.sponsor6image, "sponsor-6");

            sponsorArray.push(sponsor6);
        }

        if (bank.sponsor7name) {
            sponsor7["title"] = bank.sponsor7name;
            sponsor7["link"] = bank.sponsor7link;
            sponsor7["image"] = bank.sponsor7image; 
            sponsor7["text"] = bank.sponsor7text;
            sponsor7["id"] = "sponsor-7";
            setSponsorImage(bank.sponsor7image, "sponsor-7");

            sponsorArray.push(sponsor7);
        }

        if (bank.sponsor8name) {
            sponsor8["title"] = bank.sponsor8name;
            sponsor8["link"] = bank.sponsor8link;
            sponsor8["image"] = bank.sponsor8image; 
            sponsor8["text"] = bank.sponsor8text;
            sponsor8["id"] = "sponsor-8";
            setSponsorImage(bank.sponsor8image, "sponsor-8");
            
            sponsorArray.push(sponsor8);
        }
    }

    useEffect(() => {
        setBankId(searchParams.get('bankId') ? searchParams.get('bankId') : "fundraise-recycle");
        setupDefaultSponsors();
    }, []);

    useEffect(() => {
        fetchBankData();
    }, [bankId]);

    useEffect(() => {
        fetchBankPhoto();
        fetchSponsors();
        document.title = (bank.name + ": Fundraise & Recycle");
    }, [bank]);

    const incrementShareCount = async () => {
        if (sharedState) {
            return;
        }
        const bankRef = doc(db, "banks", bankId);
        const bankRefSnap = await getDoc(bankRef);
        var shareCount = bankRefSnap.data().shareCount;
        if (bankRefSnap.data().shareCount) {
            shareCount++;
        } else {
            shareCount = 1;
        }
        
        await updateDoc(bankRef, {
            shareCount: shareCount,
        });

        setShareCount(shareCount);
        setSharedState(true);
    }

    return (
        <PageContainer>
            <SocialButtons />
        {
            popupActive ?
            <ExitPopup onClick={() => {setPopupState(false)}}>X</ExitPopup> :
            ""
        }
        {
            popupActive ?
            <MailchimpPopup exitFunction={setPopupState} /> :
            ""
        }
        {
            sharePopupActive ?
            <SharePopup exitFunction={setSharePopup} /> :
            ""
        }
        {
            sponsorPopupState ?
            <SponsorPopup exitFunction={setSponsorPopupState} bankId={bankId} bankName={bank.name} /> :
            ""
        }
            <Header isHome={true} />
            {
                viewBankLoggedInState ?
                <AdminMenu bankId={bankId} /> :
                ""
            }
            <HeroSection>
                <HeroContent>
                    <VideoStatSection>
                        <VideoContainer>
                            {
                                bankImg ?
                                    isVideo ?
                                    <figure><video preload="metadata" controls src={`${bankImg}#t=0.1`}></video></figure> :
                                    <img src={bankImg} alt="" /> :
                                <figure><video preload="metadata" controls src={`https://fundraise-recycle.s3.us-west-002.backblazeb2.com/fr-bank-default.mp4#t=0.1`}></video></figure>
                            }
                        </VideoContainer>
                        <StatContainer>
                             <Title>{bank.name ? bank.name : "Bank not found"}</Title>
                            <Stats>
                                {bank.quantityRecycled ? <QuantityStat><span>{bank.quantityRecycled ? bank.quantityRecycled : "loading..."}kg</span><br />Recycled</QuantityStat> : "" }
                                {bank.moneyRaised ? <RaisedStat><span>£{bank.moneyRaised ? bank.moneyRaised : "loading..."}</span><br />Fundraised</RaisedStat> : "" }
                                {/* bank.benches ? <RaisedStat><span>{bank.benches ? bank.benches : "loading..."}</span><br />Benches</RaisedStat> : "" */}
                                {
                                    parseInt(bank.treesPlanted) ?
                                        <TreesStat><span>{bank.treesPlanted ? bank.treesPlanted : "loading..."}</span><br />Trees Planted</TreesStat>
                                        :   String(bank.treesPlanted).charAt(0) == "h" ?
                                            <TreesStat>
                                                <a href={bank.treesPlanted ? bank.treesPlanted : "#"} target="_blank">
                                                    Donate Here
                                                </a>
                                            </TreesStat> : ""
                                }
                            </Stats>
                            {
                                bank.goal !== "" ?
                                <GoalText>{bank.goal ? bank.goal : "loading..."}</GoalText> :
                                ""
                            }
                        </StatContainer>
                    </VideoStatSection>
                </HeroContent>
            </HeroSection>
            <CTAStrip>
                <CTAContainer className="cta-1" onClick={() => {navigate(`/book-collection?bankId=${bankId}`);}}>
                    <CTAIcon src={require('../assets/images/CollectionIcon.png')} />
                    <CTAText>START A<br/>CAMPAIGN</CTAText>
                </CTAContainer>
                <CTAContainer className="cta-2" onClick={() => {setSponsorPopupState(true)}}>
                    <CTAIcon src={require('../assets/images/MailIcon.png')} />
                    <CTAText>ADVERTISE<br/>YOUR BUSINESS</CTAText>
                </CTAContainer>
                <CTAContainer className="cta-3" onClick={() => {
                    if (navigator.share) {
                        navigator.share({
                            title: window.title,
                            text: `Would you help me organise a clothes collection on behalf of ${bank ? bank.name : "this organisation"}?\n\nThey’re fundraising ${bank ? bank.goal : ","} and by recycling your unwanted clothes we can help them get a step closer.\n\nPlease accept my invite to join the campaign and then share this invitation with friends and family who would also like to support ${bank ? bank.name : "this organisation"}.\nThank you!`,
                            url: window.location.href,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                        incrementShareCount();
                    } else {
                        setSharePopup(true);
                        incrementShareCount();
                    }
                }}>
                    <CTAIcon src={require('../assets/images/ShareIcon.png')} />
                    <CTAText>SHARE OUR<br />MISSION</CTAText>
                </CTAContainer>
            </CTAStrip>
            <TestContainer>
            <iframe width={"100%"} height={"100%"} src={"http://online.fundraiserecycleltd.co.uk/search"}></iframe>
            </TestContainer>
            <AdvertStrip>
                <SponsorStrip>Thank you to our sponsors</SponsorStrip>
                <AdvertCardContainer>
                    { sponsors.length > 0 ? sponsors.map((tile) => {
                        if (tile.image) {
                            return (
                                <AdvertCard key={tile["title"]} href={tile["link"] ? tile["link"] : "#"} target={"_blank"}>
                                    <img id={tile.id} src={tile.image} /> 
                                </AdvertCard>
                            );
                        }
                    }) : ""}
                </AdvertCardContainer>
                <ShareCount>{shareCount} Shares</ShareCount>
            </AdvertStrip>
            <MapStrip className='map-strip'>
                {
                    bank.mapEmbed ?
                    <div dangerouslySetInnerHTML={ { __html: bank.mapEmbed } }></div> :
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2301.687755290906!2d-1.3662101839909715!3d54.76787647498465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e8a72cab35f17%3A0xdecf85917518b044!2sFundraise%26Recycle%20Ltd!5e0!3m2!1sen!2suk!4v1679407659700!5m2!1sen!2suk" width={"100%"} height={620} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="TESTING"></iframe>
                }
                
                <MapInfoBox>
                    <h2>Fundraise & Recycle</h2>
                    <h3>Location:</h3>
                    <p>17 Pease Rd,<br />North West Industrial Estate,<br />Peterlee SR8 2RD</p>
                    <h3 className="border-above">Phone:</h3>
                    <p>01917062199</p>
                    <h3 className="border-above">Email:</h3>
                    <p>admin@fundraiserecycleltd.co.uk</p>
                </MapInfoBox>
            </MapStrip>
            <Footer bank={bank} />
        </PageContainer>
    )
}

export default TestPage

const ShareCount = styled.p`
    text-align: center;
    font-size: 30px;
    color: white;
    background: #BE3258;
    width: 100%;
    margin: 0;
    padding: 48px 0 0 0;
`

export const MapInfoBox = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
`

const SponsorStrip = styled.h2`
    width: 100%;
    background: #BE3258;
    font-size: 42px;
    font-weight: 800;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1060px) {
        font-size: 2rem;
    }
    
    @media (max-width: 570px) {
        font-size: 6.5vw;
    }
`

const AdvertCardContainer = styled.div`
    max-width: 1080px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 2ch;
    margin: 0 auto;
`
const TestContainer = styled.div`
    width: 100%;
    height: 100vh;
`