import React from 'react'
import styled from 'styled-components'

import FBIcon from '../assets/images/social-icons/fb.svg'
import LNIcon from '../assets/images/social-icons/ln.svg'
import INIcon from '../assets/images/social-icons/in.svg'
import TWIcon from '../assets/images/social-icons/tw.svg'
import TKIcon from '../assets/images/social-icons/tk.svg'

const Footer = (props) => {
    return (
        
            <FooterContainer>
            { 
                props.bank ? 
                <>
                    <FooterLink href={props.bank.website ? props.bank.website : "https://fundraiserecycle.com"}>{props.bank.website ? props.bank.website : "https://fundraiserecycle.com"}</FooterLink>
                    <SocialIcons>
                        { props.bank.facebook ? <SocialIcon href={props.bank.facebook}><SocialImg src={FBIcon} /></SocialIcon> : "" }
                        { props.bank.instagram ? <SocialIcon href={props.bank.instagran}><SocialImg src={INIcon} /></SocialIcon> : "" }
                        { props.bank.twitter ? <SocialIcon href={props.bank.twitter}><SocialImg src={TWIcon} /></SocialIcon> : "" }
                        { props.bank.tiktok ? <SocialIcon href={props.bank.tiktok}><SocialImg src={TKIcon} /></SocialIcon> : "" }
                        { props.bank.linkedin ? <SocialIcon href={props.bank.linkedin}><SocialImg src={LNIcon} /></SocialIcon> : "" }
                    </SocialIcons>
                </> :
                <>
                <FooterLink href="https://fundraiserecycle.com" target="_blank">https://fundraiserecycle.com</FooterLink>
                </> 
            }
            </FooterContainer> 
    )
}

export default Footer

const FooterContainer = styled.div`
    background: #0C668F;
    width: 100%;
    padding: 36px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const SocialIcons = styled.div`
    margin: 8px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 22px;
`

const SocialIcon = styled.a`
    
`

const SocialImg = styled.img`
    height: 22px;
    width: auto;
`

const FooterLink = styled.a`
    color: white;
    font-weight: 200;
    font-size: 22px;
    text-align: center;
    text-decoration: none;
    letter-spacing: .1rem;
    margin-bottom: 18px;
    opacity: 0.8;

    @media (max-width: 550px) {
        font-size: 16px;
    }
    
    @media (max-width: 400px) {
        font-size: 10px;
    }
`