import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {
    setKey,
    setLanguage,
    setRegion,
    fromAddress,
  } from "react-geocode";
import geohash from 'ngeohash';
import Map from "../components/bankSearch/Map";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import PopupContent from '../components/bankSearch/PopupContent';
import SearchResults from '../components/bankSearch/SearchResults';
import BankCard from '../components/bankSearch/BankCard';
import HeroState from '../components/bankSearch/HeroState';
import SearchForm from '../components/bankSearch/SearchForm';

const BankSearch = () => {
    var markerCount = 1;
    const apiUrl = process.env.REACT_APP_API_URL;
    const [center, setCenter] = useState({ latitude: 54.7678734, longitude: -1.3665964 });
    const [fromData, setFromData] = useState({ lat: 54.7678734, lng: -1.3665964 });
    const [fromCoords, setFromCoords] = useState({ lat: 54.7678734, lng: -1.3665964 });
    const [searchResults, setResults] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [mapMarkers, setMarkers] = useState([]);
    const searchParamsForm = useRef();
    // Default to 10 mile radius
    const [searchRadius, setSearchRadius] = useState(16000);
    const [searchComplete, setSearchComplete] = useState(false);
    const [heroState, setHeroState] = useState(true);

    let key = 1;

    useEffect(() => {
        setCenter({lat: fromData['lat'], lng: fromData['lng']});
        let coords = [fromData['lat'], fromData['lng']];
        let submitData = {
            'lat': fromData['lat'],
            'lng': fromData['lng'],
            'distance': searchRadius,
        }

        if (!searchComplete) {
            var searchResults;
            
            axios
                .post(`${apiUrl}/bank/searchbygeo`, submitData)
                .then((response) => {
                    searchResults = response.data.results;
                    setResults(searchResults);
                    setMarkers(searchResults);
                    setSearchComplete(true);
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error)
                    }
                    console.log(error)
                })
            setSearchComplete(true); 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromCoords])

    const GenerateGeohash = (searchLoc) => {
        var hashData = {};
        setKey("AIzaSyCuEx43ft9p0r4ZgsmheRMuyQHlgy2Yv9w");
        setLanguage("en");
        setRegion("gb");
        fromAddress(searchLoc).then(
            (response) => {
                const {lat, lng} = response.results[0].geometry.location;
                hashData["lat"] = lat;
                hashData["lng"] = lng;
                hashData["geoHash"] = geohash.encode(lat, lng);
                setFromData(hashData);
            },
            (error) => {
                console.error(error);
                GenerateGeohash("SR8 2RD");
            }
        )
    }

    const GenLatLng = (searchLoc) => {
        var hashData = {};
        setKey("AIzaSyCuEx43ft9p0r4ZgsmheRMuyQHlgy2Yv9w");
        setLanguage("en");
        setRegion("gb");
        fromAddress(searchLoc).then(
            (response) => {
                const {lat, lng} = response.results[0].geometry.location;
                hashData["lat"] = lat;
                hashData["lng"] = lng;
                setFromCoords(hashData);
            },
            (error) => {
                console.error(error);
                GenLatLng("SR8 2RD");
            }
        )
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setSearchComplete(false);
        const { searchLoc, radius } = e.target.elements;
        if (searchLoc.value === "") {
            searchLoc.value = "SR8 2RD";
        }
        GenerateGeohash(searchLoc.value);
        setSearchRadius(radius.value);

        GenLatLng(searchLoc.value);

        setHeroState(false);
    }

    return (
        <PageContainer>
            {
                heroState ? 
                <HeroState searchFormElement={<SearchForm refRef={searchParamsForm} onSubmitFn={onSubmit} />} /> :
                <ContentContainer>
                    {
                        searchComplete ?
                        <Mapp>
                            <SearchResults>
                                {
                                    searchResults.map((bank) =>
                                        <BankCard key={key++} bank={bank} />
                                    )
                                }
                            </SearchResults>
                            <SearchMap center={center} id="map">
                                <SearchFormContainer>
                                    <SearchForm refRef={searchParamsForm} onSubmitFn={onSubmit} />
                                </SearchFormContainer>
                                <MarkerF 
                                    className="map-marker"
                                    position={center}
                                    icon="https://fundraise-recycle.s3.us-west-002.backblazeb2.com/home-icn.png"
                                />
                                {
                                    mapMarkers.map((marker) => 
                                        <MarkerF 
                                            position={{
                                                lat: marker.latitude, 
                                                lng: marker.longitude
                                            }} 
                                            key={markerCount++}
                                            onClick={() => {
                                                setSelectedCenter(marker)
                                            }}
                                            icon="http://maps.google.com/mapfiles/ms/micons/red-dot.png"
                                        />
                                    )
                                }
                                {
                                    selectedCenter && (
                                        <InfoWindowF
                                            className="popup-item"
                                            onCloseClick={() => {
                                                setSelectedCenter(null)
                                            }}

                                            position={{
                                                lat: selectedCenter.latitude, 
                                                lng: selectedCenter.longitude
                                            }}

                                            headerDisabled={true}
                                            headerContent={<h1>HEADERTEST</h1>}
                                        >
                                            <PopupContent bank={selectedCenter} />
                                        </InfoWindowF>
                                    )
                                }
                            </SearchMap>
                        </Mapp>
                        : ""
                    }
                </ContentContainer>
            }
        </PageContainer>
    )
}

export default BankSearch

const PageContainer = styled.div`
    height: 100%;
    width: 100%;
    
    .gm-style-iw-ch {
        display: none;
    }

    /* Hiding irrelevant GMaps controls */
    .gm-control-active,
    .gm-style-mtc-bbw,
    .gm-bundled-control {
        display: none;
    }

    .gm-style-iw {

        .gm-style-iw-chr {
            position: absolute;
            top: 0;
            right: 0;
            .gm-ui-hover-effect {
                z-index: 100;
            }
        }
    }
`

const ContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const SearchMap = styled(Map)`
    margin-top: 18px;
    position: relative;
`

const Mapp = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;

    @media (max-width: 960px) {
        flex-direction: column;
    }
`

const SearchFormContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 10px;

    @media (max-width: 1060px) {
        justify-content: flex-start;
    }

    @media (max-width: 960px) {
        justify-content: center;
    }
`