import React, { useState, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import heroBg from '../../assets/images/hero-bg.jpg'

const HeroState = (props) => {

    let startText = "Where fundraising & innovative textile recycling meet";
    let changedText = "Find organisations around you who Fundraise & Recycle";
    var returning = false;

    const hoverChange = () => {
        setTitleText(changedText);
    }

    const resetHover = () => {
        setTitleText(startText);
    }
    
    const beginAnimation = () => {
        setIsHovered(true);
        setAnimate(1);
    }

    const resetAnimation = () => {
        setIsHovered(false);
        setAnimate(2);
    }

    const iterationEnd = () => {
        console.log("Iteration ending")
        if (animate === 1) {
            console.log("Iteration TO text.");
            setTitleText(isHovered ? changedText : startText);
            setTimeout(() => {
                setAnimate(2);
            }, 100);
        } else if (animate == 2) {
            console.log("Animation RESET");
            setAnimate(0);
        } 
    }

    const [titleText, setTitleText] = useState(startText);
    const [animate, setAnimate] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Container>
            <Background src={heroBg} />
            <PreTitle>Fundraise & Recycle</PreTitle>
            <Title animate={animate} onMouseEnter={() => beginAnimation()} onMouseLeave={() => resetAnimation()} onAnimationEnd={() => iterationEnd()} className="search-hero-title" id="title">{titleText}</Title>
            {props.searchFormElement}
        </Container>
    )
}

export default HeroState

const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(232, 248, 255, 0.8);

    .search-form-container {
        width: 100%;
        margin-top: 20px;
    }

    .search-form {
        position: static;
        left: auto;
        top: auto;
        width: 100%;
    }
`

const Background = styled.img`
    width: 100vw;
    height: 100vh;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
`

const PreTitle = styled.h1`
    font-size: 24px;
    font-weight: 500;
    line-height: 31.88px;
    text-align: center;
    text-decoration-skip-ink: none;
    color: black;
    margin-top: 0;
`

const textVanish = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`

const Title = styled.h2`
    /*${({ animate }) => {
        if (animate === 1) {
            return css`
                animation: ${textVanish} 1s forwards;
            `;
        } else if (animate === 2) {
            return css`
                animation: ${textVanish} 1s reverse;
            `;
        } else {
            return css`
            animation: none;
            `
        }
    }};*/

    font-size: 80px;
    font-weight: 700;
    line-height: 96px;
    letter-spacing: -0.02em;
    text-align: center;
    text-decoration-skip-ink: none;
    max-width: 900px;
    padding: 0 20px;
    margin: 0;

    @media (max-width: 550px) {
        font-size: 40px;
        line-height: 48px;
    }
`