import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header'
import Footer from '../components/Footer';
import { MailchimpPopup } from '../components/MailchimpForm';
import SharePopup from '../components/SharePopup';
import SocialButtons from '../components/SocialButtons';
import { onAuthStateChanged } from 'firebase/auth';
import AdminMenu from '../components/LoggedInMenu';
import SponsorPopup from '../components/SponsorPopup';
import axios from 'axios'
import CampaignPopup from '../components/CampaignPopup';
import * as FR from '../components/NewStyledItems';
import ReactPlayer from 'react-player';

import { db, auth } from '../util/firebaseApp';
import { doc, getDoc, getDocs, collection, updateDoc } from 'firebase/firestore'

const NewProfile = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [sharedState, setSharedState] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [bank, setBank] = useState({});
    const [bankId, setBankId] = useState("a");
    const [djangoBank, setDjangoBank] = useState({});
    const [searchParams] = useSearchParams();
    const [bankImg, setImage] = useState("");
    const [sponsors, setSponsors] = useState({});
    const [popupActive, setPopupState] = useState(false);
    const [mapEmbed, setMapEmbed] = useState("");
    const [sharePopupActive, setSharePopup] = useState(false);
    const navigate = useNavigate();
    const [shareCount, setShareCount] = useState(0);
    const [viewBankLoggedInState, setLoggedInState] = useState(false);
    const [sponsorPopupState, setSponsorPopupState] = useState(false);
    const [defaultSponsors, setDefaultSponsors] = useState([]);
    const [campaignPopup, setCampaignPopup] = useState(false);
    const [campaign, setCampaign] = useState();
    const [poster, setPoster] = useState("");

    React.useEffect(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setLoggedInState(true);
          } else {
            setLoggedInState(false);
          }
        });
      }, []);

    const fetchSingleBank = () => {
        var request = `${apiUrl}/bank/${searchParams.get('bankId') ? searchParams.get('bankId') : "fundraise-recycle"}`;
        axios
            .get(request)
            .then((res) => {
                setDjangoBank(res)
                setBank(res.data)
                setShareCount(res.data['share_count']);
                setMapEmbed(res.data.map_embed)
                console.log("Bank Data:")
                console.log(res.data)
            })
            .catch((err) => {console.log(err)})
    }

    const fetchBankData = async () => {
        const docRef = doc(db, "banks", bankId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return docSnap.data();
        }
    }

    const fetchBankPhoto = () => {
        console.log("Image:")
        console.log(bank.image)
        if (bank.image) {
            if (bank.image.slice(-1) === "4") {
                setIsVideo(true);
            }
        } else {
            setIsVideo(true);
        }
        if (bank.image) {
            setImage("https://api.fundraiserecycle.com" + bank.image)
            setPoster("https://api.fundraiserecycle.com" + bank.image.replace(".mp4", ".jpg"))
        }
    }

    const setSponsorImage = (fileToGet, idToSet) => {
        var newUrl = "https://api.fundraiserecycle.com/media/" + fileToGet;
        console.log("File to get: " + newUrl)
        console.log("id to set: " + idToSet)
        if (document.getElementById(idToSet)) {
            document.getElementById(idToSet).src = (newUrl);
        }
    }

    const fetchSponsorsOld = async () => {
        var sponsorsTemp = [];

        fetchSponsorData(sponsorsTemp)
            .then(() => {
                if (sponsorsTemp.length > 0) {
                    setSponsors(sponsorsTemp);
                } else {
                    setSponsors(defaultSponsors);
                }
            })
    }

    const fetchSponsors = async () => {
        var tempSponsors = []
        if (bank.sponsor_slots) {
            for (var i = 0; i < bank.sponsor_slots.length; i++) {
                axios
                    .get(`/sponsorslot/${bank.sponsor_slots[i]}`)
                    .then((res) => {
                        tempSponsors.push(res.data)
                    })
                    .catch((err) => {console.log(err)})
            }
            setSponsors(tempSponsors)
        }
    }

    const setupDefaultSponsors = async () => {
        let temp = [];
        const querySnapshot = await getDocs(collection(db, "default-sponsors"));
        querySnapshot.forEach((doc) => {
          temp.push(doc.data());
        });
        setSponsors(temp);
        setupDefaultSponsors(temp);
    }

    const fetchSponsorData = async (sponsorArray) => {
        let sponsor1 = {};
        let sponsor2 = {};
        let sponsor3 = {};
        let sponsor4 = {};
        let sponsor5 = {};
        let sponsor6 = {};
        let sponsor7 = {};
        let sponsor8 = {};

        if (bank.sponsor1name) {
            sponsor1["title"] = bank.sponsor1name;
            sponsor1["link"] = bank.sponsor1link;
            sponsor1["image"] = bank.sponsor1image; 
            sponsor1["text"] = bank.sponsor1text;
            sponsor1["id"] = "sponsor-1";
            setSponsorImage(bank.sponsor1image, "sponsor-1");

            sponsorArray.push(sponsor1);
        }

        if (bank.sponsor2name) {
            sponsor2["title"] = bank.sponsor2name;
            sponsor2["link"] = bank.sponsor2link;
            sponsor2["image"] = bank.sponsor2image; 
            sponsor2["text"] = bank.sponsor2text;
            sponsor2["id"] = "sponsor-2";
            setSponsorImage(bank.sponsor2image, "sponsor-2");

            sponsorArray.push(sponsor2);
        }

        if (bank.sponsor3name) {
            sponsor3["title"] = bank.sponsor3name;
            sponsor3["link"] = bank.sponsor3link;
            sponsor3["image"] = bank.sponsor3image; 
            sponsor3["text"] = bank.sponsor3text;
            sponsor3["id"] = "sponsor-3";
            setSponsorImage(bank.sponsor3image, "sponsor-3");
            
            sponsorArray.push(sponsor3);
        }

        if (bank.sponsor4name) {
            sponsor4["title"] = bank.sponsor4name;
            sponsor4["link"] = bank.sponsor4link;
            sponsor4["image"] = bank.sponsor4image; 
            sponsor4["text"] = bank.sponsor4text;
            sponsor4["id"] = "sponsor-4";
            setSponsorImage(bank.sponsor4image, "sponsor-4");

            sponsorArray.push(sponsor4);
        }

        if (bank.sponsor5name) {
            sponsor5["title"] = bank.sponsor5name;
            sponsor5["link"] = bank.sponsor5link;
            sponsor5["image"] = bank.sponsor5image; 
            sponsor5["text"] = bank.sponsor5text;
            sponsor5["id"] = "sponsor-5";
            setSponsorImage(bank.sponsor5image, "sponsor-5");

            sponsorArray.push(sponsor5);
        }

        if (bank.sponsor6name) {
            sponsor6["title"] = bank.sponsor6name;
            sponsor6["link"] = bank.sponsor6link;
            sponsor6["image"] = bank.sponsor6image; 
            sponsor6["text"] = bank.sponsor6text;
            sponsor6["id"] = "sponsor-6";
            setSponsorImage(bank.sponsor6image, "sponsor-6");

            sponsorArray.push(sponsor6);
        }

        if (bank.sponsor7name) {
            sponsor7["title"] = bank.sponsor7name;
            sponsor7["link"] = bank.sponsor7link;
            sponsor7["image"] = bank.sponsor7image; 
            sponsor7["text"] = bank.sponsor7text;
            sponsor7["id"] = "sponsor-7";
            setSponsorImage(bank.sponsor7image, "sponsor-7");

            sponsorArray.push(sponsor7);
        }

        if (bank.sponsor8name) {
            sponsor8["title"] = bank.sponsor8name;
            sponsor8["link"] = bank.sponsor8link;
            sponsor8["image"] = bank.sponsor8image; 
            sponsor8["text"] = bank.sponsor8text;
            sponsor8["id"] = "sponsor-8";
            setSponsorImage(bank.sponsor8image, "sponsor-8");
            
            sponsorArray.push(sponsor8);
        }
    }

    const setupCampaignInfo = async () => {
        console.log("given a campaign id")
        axios
            .get(`${apiUrl}/collectionrequest/${searchParams.get('campaignid')}`)
            .then((res) => {
                console.log("GOT CAMPAIGN DATA:")
                console.log(res.data)
                setCampaign(res.data)
                setCampaignPopup(true)
            })
            .catch((err) => {
                console.log("CAMPAIGN ERROR:")
                console.log(err)
            })
    }

    useEffect(() => {
        setBankId(searchParams.get('bankId') ? searchParams.get('bankId') : "fundraise-recycle");
        setupDefaultSponsors();
        if (searchParams.get("campaignid")) {
            setupCampaignInfo()
        }
    }, []);

    useEffect(() => {
        fetchSingleBank();
    }, [bankId]);

    useEffect(() => {
        fetchSponsorsOld();
        document.title = (bank.name + ": Fundraise & Recycle");
        fetchBankPhoto();
        
    }, [bank]);

    const incrementShareCount = async () => {
        console.log("Increasing share count.");
        var request = `${apiUrl}/bank/inc-share/${bankId}`;

        if (sharedState) {
            return;
        }

        axios
            .get(`${apiUrl}/bank/inc-share/${bankId}`)
            .then((response) => {
                console.log(response);
                setShareCount(response.data["new_share_count"]);
                setSharedState(true);
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error)
                }
                console.log(error)
                //alert("Share count update failed, please try again");
            })
    }

    return (
        <FR.PageContainer>
            <SocialButtons />
        {
            campaignPopup ?
            <CampaignPopup campaign={campaign} bank={bank} exitFunction={setCampaignPopup} /> :
            ""
        }
        {
            popupActive ?
            <FR.ExitPopup onClick={() => {setPopupState(false)}}>X</FR.ExitPopup> :
            ""
        }
        {
            popupActive ?
            <MailchimpPopup exitFunction={setPopupState} /> :
            ""
        }
        {
            sharePopupActive ?
            <SharePopup exitFunction={setSharePopup} /> :
            ""
        }
        {
            sponsorPopupState ?
            <SponsorPopup exitFunction={setSponsorPopupState} bankId={bankId} bankName={bank.name} /> :
            ""
        }
            <Header isHome={true} />
            {
                viewBankLoggedInState ?
                <AdminMenu bankId={bankId} /> :
                ""
            }
            <FR.HeroSection>
                <FR.HeroSwirl src={require('../assets/images/hero-swirl.svg').default} />
                <FR.HeroContent>
                    <FR.VideoStatSection>
                        <FR.VideoContainer>
                            <FR.Title>{bank.name ? bank.name : "Bank not found"}</FR.Title>
                            <FR.VideoBorder>
                                {
                                    bankImg ?   
                                        isVideo ?
                                        <figure><video preload="metadata" controls src={bankImg} poster={poster}></video></figure> :
                                        <img src={bankImg} alt="" /> :
                                    <figure><video preload="metadata" controls src={`https://fundraise-recycle.s3.us-west-002.backblazeb2.com/fr-bank-default.mp4#t=0.1`} poster={`https://fundraise-recycle.s3.us-west-002.backblazeb2.com/fr-bank-default.jpg`}></video></figure>
                                }
                            </FR.VideoBorder>
                        </FR.VideoContainer>
                        <FR.ContentContainer>
                            {
                                bank.goal !== "" ?
                                <FR.GoalText>{bank.goal ? bank.goal : "loading..."}</FR.GoalText> :
                                ""
                            }
                            <FR.StatContainer>
                                <FR.Stats>
                                    {bank.total_recycled >= 0 ? <FR.QuantityStat><span>{bank.total_recycled >= 0 ? bank.total_recycled.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "loading..."}kg</span><br />RECYCLED</FR.QuantityStat> : ""}
                                    {bank.money_raised >= 0 ? <FR.RaisedStat><span>£{bank.money_raised >= 0 ? bank.money_raised.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "loading..."}</span><br />FUNDRAISED</FR.RaisedStat> : "" }
                                    {/* bank.benches ? <RaisedStat><span>{bank.benches ? bank.benches : "loading..."}</span><br />Benches</RaisedStat> : "" */}
                                    {
                                        parseInt(bank.trees) ?
                                            <FR.TreesStat><span>{bank.trees >= 0 ? bank.trees : "loading..."}</span><br />TREES PLANTED</FR.TreesStat>
                                            :   bank.donation_link ?
                                                <FR.TreesStat className='donate-link'>
                                                    <a href={bank.donation_link ? bank.donation_link : "#"} target="_blank">
                                                        DONATE HERE
                                                    </a>
                                                </FR.TreesStat> : ""
                                    }
                                </FR.Stats>
                            </FR.StatContainer>
                        </FR.ContentContainer>
                    </FR.VideoStatSection>
                </FR.HeroContent>
            </FR.HeroSection>
            <FR.CTAStrip>
                <FR.CTAContainer className="cta-1" onClick={() => {navigate(`/book-collection?bankId=${bankId}`);}}>
                    <FR.CTAIcon src={require('../assets/images/campaign-icn.png')} />
                    <FR.CTAText>START A<br/>CAMPAIGN</FR.CTAText>
                </FR.CTAContainer>
                <FR.CTAContainer className="cta-2" onClick={() => {setSponsorPopupState(true)}}>
                    <FR.CTAIcon src={require('../assets/images/advertise-icn.png')} />
                    <FR.CTAText>ADVERTISE YOUR<br/>BUSINESS</FR.CTAText>
                </FR.CTAContainer>
                <FR.CTAContainer className="cta-3" onClick={() => {
                    if (navigator.share) {
                        navigator.share({
                            title: window.title,
                            text: `I choose you to help me and join my Fundraise and Recycle campaign by recycling and clearing out your wardrobe to help fundraise for ${bank ? bank.name : '.'}.`,
                            url: window.location.href,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                        incrementShareCount();
                    } else {
                        setSharePopup(true);
                        incrementShareCount();
                    }
                }}>
                    <FR.CTAIcon src={require('../assets/images/share-icn.png')} />
                    <FR.CTAText>SHARE OUR<br />MISSION</FR.CTAText>
                </FR.CTAContainer>
            </FR.CTAStrip>
            <FR.AdvertStrip>
                <FR.SponsorStrip>THANK YOU TO OUR SPONSORS</FR.SponsorStrip>
                <FR.AdvertCardContainer>
                    { sponsors.length > 0 ? sponsors.map((tile) => {
                        if (tile.image) {
                            return (
                                <FR.AdvertCard key={tile["title"]} backgroundimage={`https://api.fundraiserecycle.com/media/${tile.image}`} href={tile["link"] ? tile["link"] : "#"} target={"_blank"}>
                                    <img id={tile.id} src={`https://api.fundraiserecycle.com/media/${tile.image}`} /> 
                                </FR.AdvertCard>
                            );
                        }
                    }) : ""}
                </FR.AdvertCardContainer>
                <FR.ShareCount>{shareCount} SHARES</FR.ShareCount>
            </FR.AdvertStrip>
            <FR.MapStrip className='map-strip'>
                {
                    mapEmbed ?
                    <div dangerouslySetInnerHTML={ { __html: mapEmbed } }></div> :
                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2301.687755290906!2d-1.3662101839909715!3d54.76787647498465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e8a72cab35f17%3A0xdecf85917518b044!2sFundraise%26Recycle%20Ltd!5e0!3m2!1sen!2suk!4v1679407659700!5m2!1sen!2suk" width={"100%"} height={620} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="TESTING"></iframe>
                }
            </FR.MapStrip>
            <Footer bank={bank} />
        </FR.PageContainer>
    )
}

export default NewProfile