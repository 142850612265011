import styled from 'styled-components'

export const PageContainer = styled.div`
    overflow: hidden;
    max-width: 1690px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #F8FDFF;

    .donate-link {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

export const HeroSection = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 0;
    background: #0C668F;
    border-radius: 32px;
`

export const HeroContent = styled.div`  
    padding: 100px 0;
    margin-top: -30px;
    z-index: 5;
    position: relative;
`

export const VideoStatSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    width: min-content;
    gap: 80px;
    margin: 0 auto;

    @media (max-width: 1090px) {
        gap: 20px;
    }

    @media (max-width: 1060px) {
        flex-direction: column;
        padding-bottom: 0;
        padding-top: 20px;
    }
`

export const VideoBorder = styled.div`
    width: 40vw;
    overflow: hidden;
    background: white;
    padding: 10px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    figure {
        margin: 0;
        line-height: 0;
    }

    @media (max-width: 1360px) {
        width: 50vw;
    }

    video {
        width: 100%;
        border-radius: 22px;

    }

    @media (max-width: 1060px) {
        width: 80vw;
    }
`

export const VideoContainer = styled.div`
    margin: 0 auto;
`

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`

export const StatContainer = styled.div`
    background: white;
    display: flex;
    border-radius: 32px;
    align-items: center;
    justify-content: center;
`

export const Stats = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 12px;
    width: 400px;
    padding: 10px;

    span {
        font-family: "futura-pt";
        font-size: 42px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
        text-decoration-skip-ink: none;
        color: #174C64;

        @media (max-width: 460px) {
            font-size: 10px;
        }
    }

    p {
        color: #174C64;
        margin: 0;
        padding: 10px 20px;
        text-align: center;
        font-family: "futura-pt";
        font-size: 18px;
        line-height: 18px;
        font-weight: 700;
        text-decoration-skip-ink: none;
        border-radius: 22px;
        width: 100%;
        height: 86px;
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        font-weight: bold;
        line-height: 2;
    }
`

export const QuantityStat = styled.p`
    background: #FBEA4C;
`

export const RaisedStat = styled.p`
    background: #FCDA00;
`

export const TreesStat = styled.p`
    background: #FFC50D;
`

export const Title = styled.h1`
    font-weight: 600;
    line-height: 1;
    font-size: 72px;
    max-width: 50vw;
    color: white;
    margin: 0;
    line-height: 76px;
    text-align: left;
    text-decoration-skip-ink: none;
    margin-bottom: 20px;

    @media (max-width: 1060px) {
        text-align: center;
        max-width: none;
    }

    @media (max-width: 684px) {
        max-width: 100%;
    }
`

export const GoalText = styled.p`
    color: white;
    max-width: 444px;
    margin: 0 0 20px 0;
    font-family: "futura-pt", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 34px;
    text-align: center;
    text-decoration-skip-ink: none;


    @media (max-width: 1060px) {
        text-align: center;
        max-width: 80vw;
        margin: 0 auto 20px auto;
    }
`

export const CTAStrip = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 18px;
    width: 80%;
    margin: -54px 0 48px 0;
    margin-top: -54px;

    @media (max-width: 960px) {
        column-gap: 8px;
        width: auto;
    }
`

export const CTAContainer = styled.div`
    background: #EF3D3C;
    border-radius: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;   
    position: relative;
    height: 108px;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.5);

    :hover {
        //background: #BA3332;
    }

    @media (max-width: 720px) {
        height: 120px;
    }

    @media (max-width: 960px) {
        flex-direction: column;
        padding: 0 12px;

        img {
            margin: 0;
            margin-bottom: 12px;
        }

        p {
            margin: 0;
            text-align: center !important;
        }
    }
`

export const CTAIcon = styled.img`
    margin-right: 20px;
    width: 64px;
    height: auto;
    fill: white;

    @media (max-width: 680px) {
        width: 48px;
        height: auto;
    }
`

export const CTAText = styled.p`
    font-size: 28px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 21.25px;
    text-align: left;
    text-decoration-skip-ink: none;

    @media (max-width: 720px) {
        font-size: 12px;
        line-height: 16px;
    }
`

export const AdvertStrip = styled.div`
    width: 100%;
`

export const AdvertCard = styled.a`
    box-shadow: 6.04px 6.04px 30.22px 0px rgba(0, 0, 0, 0.1) inset;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-image: url(${props => (props.backgroundimage)});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 24px;

    p {
        color: rgb(0, 57, 85);
        font-size: 2.5vw;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;
    }

    img {
        max-height: 100%;
        max-width: 100%;
        margin: auto;
        object-fit: cover;
        object-position: center;
        z-index: -1;
    }

    @media (max-width: 1060px) {
        width: 22vw;
        height: 14vw;
    }

    @media (max-width: 920px) {
        border-radius: 16px;
        width: 24vw;
    }
`

export const MapStrip = styled.div`
    margin-top: 48px;
    width: 100%;
    height: 450px;
    overflow: hidden;

    position: relative;
    border-radius: 16px;

    iframe {
        border: 0;
    }
`

export const ExitPopup = styled.a`
    color: white;
    font-size: 36px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 101;
    cursor: pointer;
`

export const MapInfoContent = styled.div`
    margin: auto;
    border-radius: 60px;
    box-shadow: 0px 0px 26px -1px rgba(0,0,0,0.75);
    background: white;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;

    h2 {
        text-align: center;
    }

    .border-above {
        border-top: 3px solid #003955;
    }

    h3 {
        position: relative;
        text-align: center;
        margin: 0;
        padding-top: 8px;
    }

    p {
        text-align: center;
    }
`

export const ShareCount = styled.p`
    font-family: 'futura-pt';
    font-size: 24px;
    font-weight: 500;
    line-height: 31.2px;
    letter-spacing: -0.01em;

    text-align: center;
    font-size: 30px;
    color: white;
    margin: 0;
    color: #033A57;
`

export const MapInfoBox = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
`

export const SponsorStrip = styled.h2`
    font-size: 48px;
    font-weight: 500;
    line-height: 55.2px;
    letter-spacing: -0.02em;
    margin-top: 0;
    text-decoration-skip-ink: none;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #033A57;
`

export const AdvertCardContainer = styled.div`
    max-width: 1440px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2ch;
    margin: 48px auto;
    grid-auto-rows: 1fr;

    @media (max-width: 920px) {
        gap: 1ch;
        max-width: 100vw;
    }
`

export const HeroSwirl = styled.img`
    position: absolute;
    z-index: 1;
    width: 120%;
    transform: rotateZ(-10deg);
    left: -10%;
    top: 0%;
`