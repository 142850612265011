import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import locationIcon from '../../assets/images/location-icn.svg';
import {
    setKey,
    setLanguage,
    setRegion,
    fromLatLng,
    geocode,
    RequestType
  } from "react-geocode";

const SearchForm = (props) => {

    const [userLocation, setUserLocation] = useState(null);
    const [addressFound, setAddressFound] = useState("");

    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation(position.coords);
                    getAddressFromCoords(position.coords.latitude, position.coords.longitude)
                },
                (error) => {
                    console.log("Error getting location: ", error);
                }
            );
        } else {
            console.log("geolocation is not supported on this browser");
        }
    }

    const getAddressFromCoords = async (lat, lng) => {
        var hashData = {};
        setKey("AIzaSyCuEx43ft9p0r4ZgsmheRMuyQHlgy2Yv9w");
        setLanguage("en");
        setRegion("gb");
        fromLatLng(lat, lng)
            .then(({results}) => {
                //console.log(results);
                setAddressFound(results[0]);
            })

            geocode(RequestType.LATLNG, (lat.toString() + ',' + lng.toString()), {
                location_type: "ROOFTOP", // Override location type filter for this request.
                enable_address_descriptor: true, // Include address descriptor in response.
              })
                .then(({ results }) => {
                  const address = results[0].formatted_address;
                  const { city, state, country } = results[0].address_components.reduce(
                    (acc, component) => {
                      if (component.types.includes("locality"))
                        acc.city = component.long_name;
                      else if (component.types.includes("administrative_area_level_1"))
                        acc.state = component.long_name;
                      else if (component.types.includes("country"))
                        acc.country = component.long_name;
                      return acc;
                    },
                    {}
                  );
                  //console.log(city, state, country);
                  //console.log(address);

                  if (address) {
                    setAddressFound(address)
                  } else if (city) {
                    setAddressFound(city)
                  } else if (state) {
                    setAddressFound(state)
                  } else if (country) {
                    setAddressFound(country)
                  }
                })
                .catch(console.error);
    }

    useEffect(() => {
    }, [userLocation]) 

    useEffect(() => {
        var textBox = document.getElementById("searchLoc")
        if (textBox) {
            textBox.value = addressFound
        }
    }, [addressFound])

    return (
        <Container className="search-form-container">
            <SearchQueryForm ref={props.refRef} onSubmit={props.onSubmitFn} className="search-form" noValidate>
                <div className="form-fields-container top-level">
                    <img onClick={() => {getUserLocation()}} src={locationIcon} />
                    <div className="form-field-container search-field-container">
                        <input className="form-control search-field" placeholder="Search..." type="text" id="searchLoc" required />
                    </div>
                    <div className="form-field-container">
                        <select defaultValue={16000} id="radius" name="radius">
                            <option value={1600}>+1 Mile</option>
                            <option value={3200}>+2 Miles</option>
                            <option value={6400}>+5 Miles</option>
                            <option value={16000}>+10 Miles</option>
                            <option value={160000}>+100 Miles</option>
                        </select>
                    </div>
                    <button className="btn btn-danger" type="submit">Search</button>
                </div>
            </SearchQueryForm>
        </Container>
    )
}

export default SearchForm

const Container = styled.div`
    @media (max-width: 550px) {
    }
`

const SearchQueryForm = styled.form`
    z-index: 999;
    height: 86px;
    display: flex;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 480px;
    width: 100%;
    padding: 14px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(12, 102, 142, 1);
    border-radius: 64px;

    @media (max-width: 1120px) {
    }

    .form-fields-container {
        display: flex;
        flex-flow: row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    .top-level img {
        max-height: 70%;
        width: auto;
        cursor: pointer;
    }

    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
    }

    .search-field-container {
        flex: 1 0;
    }

    .search-field-container input {
        width: 100%;
        background: none;
        color: white;
        font-size: 32px;
        font-weight: 400;
        line-height: 44.1px;
        text-align: left;
        text-decoration-skip-ink: none;
        padding-left: 10px;
    }

    .search-field-container input:focus-visible {
        outline: -webkit-focus-ring-color auto 0;
    }

    input::placeholder {
        color: white;
    }

    input,
    select {
        box-sizing: border-box;
        border-color: white;
        height: 38px;
        background: none;
        color: white;
        appearance: none;
    }

    select {
        border-width: 0 0 0 2px;
        width: 128px;
        padding-left: 14px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    /*button {
        border: 0;
        border-radius: 0 24px 0 24px;
        padding: 8px 16px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        font-weight: normal;
    } */

    button {
        border: 0;
        background: white;
        outline: 0;
        padding: 0;
        border-radius: 64px;
        color: rgba(3, 58, 87, 1);
        padding: 12px 32px;
    }

    .search-field {
        border: 0;
    }

    input, select {
        :active,
        :focus,
        :focus-visible {
            border: 0;
            outline: 0;
            outline: -webkit-focus-ring-color auto 0;
        }   
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }

    .checkout-btn {
        margin: 24px auto;
    }

    @media (max-width: 400px) {
        select {
            width: 80px;
        }
        .search-field-container input {
            font-size: 22px;
        }
        transform: scale(0.8);
    }
    
`